import React from 'react';
import {Navigate, Outlet, Route, Routes, useNavigate, useParams} from 'react-router-dom';
import {ErrorBoundary, FallbackProps} from 'react-error-boundary';
import axios from 'axios';
import {ImportsPage} from '../pages/ImportsPage';
import {ImportPage} from '../pages/ImportPage';
import {ValidationResolverPage} from "../pages/ValidationResolverPage";
import {CustomerImportProfileMappingPage} from "../pages/mappings/CustomerImportProfileMappingPage";
import {CustomerImportSluitpotMappingPage} from "../pages/mappings/CustomerImportSluitpotMappingPage";
import {SillTypeMappingPage} from "../pages/mappings/SillTypeMappingPage";
import {CompartmentLayoutMappingPage} from "../pages/mappings/CompartmentLayoutMappingPage";
import {StopProfilesMappingPage} from "../pages/mappings/StopProfilesMappingPage";
import {GlazingProfilesMappingPage} from "../pages/mappings/GlazingProfilesMappingPage";
import {GlazingBarMappingPage} from "../pages/mappings/GlazingBarMappingPage";
import {CornerpieceMappingPage} from "../pages/mappings/CornerpieceMappingPage";
import {ImportMappingPage} from "../pages/mappings/ImportMappingPage";


export const ImportRoutePath = {

    list: 'list',

    import: ':id',
    importValidation: 'c/:customerId/v/:validationId',

    importMapping: 'mapping',
    importProfileMapping: 'profile',
    importSluitpotMapping: 'sluitpot',
    importSillTypeMapping: 'sills',
    importStopProfileMapping: 'stop-profiles',
    importGlazingprofileMapping: 'glazingprofiles',
    importGlazingbarMapping: 'glazingbars',
    importCornerpiecesMapping: 'cornerpieces',
    importCompartmentLayoutMapping: 'compartment-layout',
	importArticleMapping: 'articles',

    importLink: (id: string) => {
        return ImportRoutePath.import.replace(':id', id);
    },

    importValidationLink: (id: string, customerId: string, validationId: string) => {
        return ImportRoutePath.importValidation
            .replace(':id', id)
            .replace(':customerId', customerId)
            .replace(':validationId', validationId)
            ;
    },
};

const ImportRoutes: React.FC = () => {
    return (
        <Routes>
            <Route element={<ErrorBoundary FallbackComponent={AclFallback}> <Outlet/>
            </ErrorBoundary>}>

                <Route path={ImportRoutePath.importMapping} element={<ImportMappingPage/>}>
                    <Route path={ImportRoutePath.importProfileMapping} element={<CustomerImportProfileMappingPage/>}/>
                    <Route path={ImportRoutePath.importSluitpotMapping} element={<CustomerImportSluitpotMappingPage/>}/>
                    <Route path={ImportRoutePath.importSillTypeMapping} element={<SillTypeMappingPage/>}/>
                    <Route path={ImportRoutePath.importCompartmentLayoutMapping} element={<CompartmentLayoutMappingPage/>}/>
                    <Route path={ImportRoutePath.importStopProfileMapping} element={<StopProfilesMappingPage/>}/>
                    <Route path={ImportRoutePath.importGlazingprofileMapping} element={<GlazingProfilesMappingPage/>}/>
                    <Route path={ImportRoutePath.importGlazingbarMapping} element={<GlazingBarMappingPage/>}/>
                    <Route path={ImportRoutePath.importCornerpiecesMapping} element={<CornerpieceMappingPage/>}/>
                    <Route path={ImportRoutePath.importArticleMapping} element={<>TODO</>}/>
                </Route>
                <Route path={ImportRoutePath.list} element={<ImportsPage/>}/>
                <Route path={ImportRoutePath.import} element={<><ImportPage/><Outlet/></>}>
                    <Route path={ImportRoutePath.importValidation} element={<ValidationResolverPage/>}/>
                </Route>
            </Route>
            <Route index element={<Navigate to={ImportRoutePath.list}/>}/>
        </Routes>
    );
};

const AclFallback: React.FC<FallbackProps> = (props) => {
    if (axios.isAxiosError(props.error)) {
        if (props.error.response?.status === 404) {
            return <>Not found</>;
        }
        if (props.error.response?.status === 500) {
            return <>Server error</>;
        }
    }
    return <>Error</>;
};

export default ImportRoutes;
