import React, {useEffect, useState} from 'react';
import {useFormikContext} from 'formik';
import {StandardWallConnectionEditor} from './StandardWallConnectionEditor';
import {
    WallConnectionType,
    SillConfigurationStandardWallConnection,
    SillConfigurationAngledWallConnection,
    SillConfigurationWallConnection,
    ConfiguratorWallConnectionAngle,
} from '../../../modules/api-client/generated';
import ApiClient from '../../../modules/api-client/ApiClient';
import WallConnectionPreview from '../WallConnectionPreview';
import {AngledWallConnectionEditor} from "./AngledWallConnectionEditor";


export interface WallConnectionEditorOptions {
    width: number;
    position: 'left' | 'right';
    angles?: ConfiguratorWallConnectionAngle[];
}

export interface WallConnectionEditorProps extends WallConnectionEditorOptions {
    id: string;
}

export type WallConnectionEditorValue = SillConfigurationStandardWallConnection | SillConfigurationAngledWallConnection;

const WallConnectionEditor: React.FC<WallConnectionEditorProps> = (props) => {

    const [previewSvg, setPreviewSvg] = useState<string | null>(null);
    const [error, setError] = useState<Error | null>(null);
    const {values, setValues, isValid} = useFormikContext<WallConnectionEditorValue>();


    const validate = async (wc: SillConfigurationWallConnection): Promise<void> => {

        const response = await ApiClient.Pim.Configurator.validateWallConnection(props.width, props.position === 'right', undefined, wc)
            .then(x => x.data);

        if (response.isValid) {
            await setValues(response.wallConnection!);
            setPreviewSvg(response.svg!)
        } else {
            throw new Error(response.error ?? 'Muuraansluiting in niet geldig');
        }
    }

    const onChange = async (wc: SillConfigurationWallConnection): Promise<void> => {
        // wc.wallConnectionType = WallConnectionType.Standard;
        await validate(wc);
    };

    useEffect(() => {
        validate(values).then();
    }, []);

    const setType = async (value: WallConnectionType) => {

        let values: WallConnectionEditorValue;

        switch (value) {
            case WallConnectionType.Angled: {
                values = {wallConnectionType: value, angle: 10}
                break;
            }
            default: {
                values = {wallConnectionType: value}
                break;
            }
        }

        await setValues(values);
        await validate(values);
    };

    return (

        <div className='wall-connection-editor'>

            <div className='row'>
                <div className='col-4 d-flex align-items-center'>
                    {/*<pre>{JSON.stringify(values, null, 2)}</pre>*/}
                    <WallConnectionPreview svg={previewSvg}/>
                </div>
                <div className='col' style={{minHeight: '340px'}}>

                    <div className='d-grid gap-3'>

                        <div>
                            <label className='form-label'><small className='text-uppercase text-muted'>Type
                                aansluiting</small></label>

                            <select id={`${props.id}_type`} className='form-select'
                                    value={values.wallConnectionType}
                                    onChange={(e) => setType(e.target.value as WallConnectionType)}>
                                <option value={WallConnectionType.Standard}>Sponning en/of
                                    groef
                                </option>
                                <option value={WallConnectionType.Angled}>Schuin</option>
                            </select>
                        </div>

                        {error && <div className="alert alert-danger">{error.message}</div>}

                        {values.wallConnectionType === WallConnectionType.Standard &&
                            <StandardWallConnectionEditor width={props.width} onChange={onChange}/>
                        }

                        {values.wallConnectionType === WallConnectionType.Angled &&
                            <AngledWallConnectionEditor width={props.width} onChange={onChange} angles={props.angles}/>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WallConnectionEditor;

