import {
    SillConfigurationAngledWallConnection, ConfiguratorWallConnectionAngle
} from "../../../modules/api-client/generated";
import React from "react";
import {useFormikContext} from "formik";

export interface AngledWallConnectionEditorProps {
    width: number;
    onChange: (value: SillConfigurationAngledWallConnection) => Promise<void>;
    angles?: ConfiguratorWallConnectionAngle[];
}

export const AngledWallConnectionEditor: React.FC<AngledWallConnectionEditorProps> = (props) => {

    const {values} = useFormikContext<SillConfigurationAngledWallConnection>();

    const changeAngle = async (angle: number) => {
        await props.onChange({...values, angle: angle});
    };

    const innerAngles: ConfiguratorWallConnectionAngle[] = [];
    const outerAngles: ConfiguratorWallConnectionAngle[] = [];

    props.angles?.forEach((angle) => {
        if (angle.angle > 0) {
            innerAngles.push(angle);
        } else if (angle.angle < 0) {
            outerAngles.push(angle);
        }
    });


    return <div>
        <label className='form-label'><small className='text-uppercase text-muted'>Hoek</small></label>

        <select className='form-select' value={values.angle} onChange={(e) => changeAngle(Number(e.target.value))}>
            {props.angles?.map((angle, index) => <option key={index} value={angle.angle}>{angle.name}</option>)}
        </select>
    </div>
};