import React from 'react';
import {useQuery} from '@tanstack/react-query';
import ApiClient from '../../../../modules/api-client/ApiClient';
import {Link} from 'react-router-dom';
import {EkoCard, EkoCardBody} from '../../../../../_metronic/helpers';
import {TableHeader, EkoTable} from '../../../../shared/components/table';
import {EkoCardHeader, EkoCardToolbar} from '../../../../shared/components/card';
import {ContentListItem} from '../../../../modules/api-client/generated';
import Loading from '../../../../shared/components/Loading';
import { formatDate } from '../../../../shared/components/date';
import Error from "../../../../shared/components/Error";

const ContentList: React.FC = () => {
	const {isInitialLoading, data: list, isError} = useQuery<ContentListItem[]>(['homepageContentListGet'], () => ApiClient.Homepage.getContentList().then((res) => res.data));

	if (isInitialLoading) return <Loading />;

	if (isError) return <Error />;

	return (
		<>
			<EkoCard>
				<EkoCardHeader title={'Homepage content'}>
					<EkoCardToolbar>
                        <Link to={'add'} className='btn btn-primary btn-sm'>Toevoegen</Link>
					</EkoCardToolbar>
				</EkoCardHeader>
				<EkoCardBody>
					<EkoTable>
						<TableHeader>
							<th>Code</th>
							<th>Message</th>
							<th>Type</th>
							<th style={{width: "9em"}}>Aangemaakt op</th>
						</TableHeader>
						<tbody>
							{(!list || list.length === 0) && (
								<tr>
									<td colSpan={5} className="text-center">
										Geen gegevens
									</td>
								</tr>
							)}
							{list &&
								list.map((data) => (
									<tr key={data.code}>
										<td><Link to={data.id!} relative='path'>{data.code}</Link></td>
										<td>{data?.message}</td>
										<td>{data?.contentType}</td>
										<td>{formatDate(data.createdAt ?? "")}</td>
									</tr>
								))}
						</tbody>
					</EkoTable>
				</EkoCardBody>
			</EkoCard>
		</>
	);
};

export default ContentList;
