/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react';
import {
    ImportGlazingBarMappingRepresentation,
} from "../../../../modules/api-client/generated";
import {EkoTable, TableHeader} from "../../../components/table";
import {useEkosietAuth0} from "../../../../modules/auth0/core/useEkosietAuth0";
import SpinnerButton from "../../../components/SpinnerButton";
import {Button, Modal} from "react-bootstrap";
import {ImportModal} from "../../components/ImportModal";
import {EkoCard, EkoCardBody, EkoCardHeader, EkoCardToolbar} from "../../../components/card";
import {
     useGetImportGlazingBarsMappings,
} from "../../../../backoffice/modules/acl/import/hooks/use-get-import-mappings";
import Loading from "../../../components/Loading";


export const GlazingBarMappingPage: React.FC = () => {

    const {isKlant} = useEkosietAuth0();

    const {
        isInitialLoading,
        data: mappings,
        isError
    } = useGetImportGlazingBarsMappings();
    const [selectedMapping, setSelectedMapping] = useState<ImportGlazingBarMappingRepresentation | undefined>(undefined);
    const [showModal, setShowModal] = useState<boolean>(false);
    // var mutationDeleteImportMapping = useDeleteImportSluitpotMapping();

    if (isInitialLoading) {
        return <Loading />;
    }

    if (isError) {
        return <>Fout bij het ophalen van de koppelingen!</>;
    }

    return (
        <>
            <ImportModal show={showModal} handleClose={() => setShowModal(false)}
                         title={'Koppeling toevoegen of aanpassen'}
                         size={'lg'}
                         component={<>
                         TODO</>
                             // <ImportSluitpotMappingUpsert
                             //     id={selectedSluitpotMapping?.id}
                             //     customerId={selectedSluitpotMapping?.customer.id ?? isKlant ? customerId : id}
                             //     handleSucces={
                             //         () => {setSelectedSluitpotMapping(undefined); setShowModal(false)}
                             //     }
                             // />
                         }
                         fullscreen={'md-down'}/>
            <EkoCard>
                <EkoCardHeader title={'Glaslat koppelingen'} >
                    <EkoCardToolbar>
                        <Button className={'btn btn-primary'} onClick={()=>setShowModal(true)}>Toevoegen</Button>
                    </EkoCardToolbar>
                </EkoCardHeader>
                <EkoCardBody>
                    {(!mappings) ?
                        <div className={'alert alert-warning'}>
                            Er zijn nog geen koppelingen.
                        </div>
                        :
                        <EkoTable>
                            <TableHeader>
                                <th>Externe code</th>
                                <th></th>
                                <th>Ekosiet code</th>
                            </TableHeader>
                            <tbody>
                            {mappings.map((mapping) => (
                                <tr key={mapping.id}>
                                    <td><h2>{mapping.externalCode}</h2></td>
                                    <td><h1><i className={'fa fa-arrow-right'}> </i></h1></td>
                                    <td><h2>{mapping.glazingBar?.name}</h2></td>
                                    <td>
                                        {!isKlant &&
                                            <>
                                        <button
                                            type="button"
                                            className={`btn btn-light-primary btn-sm btn-light me-2 `}
                                            title="Wijzig Mapping"
                                            onClick={() => {
                                                setSelectedMapping(mapping);
                                                setShowModal(true);
                                            }}
                                        >
                                            <i className="fas fa-edit"></i> Aanpassen
                                        </button>
                                        <SpinnerButton
                                            className={`btn btn-light-danger`}
                                            title="Verwijder mapping"
                                            onClick={async () => {
                                                // await mutationDeleteImportMapping.mutateAsync(mapping.id);
                                            }}
                                        >
                                            <i className="fas fa-trash"></i> Ontkoppelen
                                        </SpinnerButton>
                                            </>
                                    }
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </EkoTable>
                    }
                </EkoCardBody>
            </EkoCard>
        </>
    );
};

