import {useQuery} from '@tanstack/react-query';
import ApiClient from '../../../../../modules/api-client/ApiClient';
import {AxiosError} from 'axios';
import {toast} from 'react-hot-toast';
import {isNotEmpty} from '../../../../../../_metronic/helpers';
import {
	ImportCompartmentLayoutMappingRepresentation, ImportCornerPieceMappingRepresentation,
	ImportGlazingBarMappingRepresentation,
	ImportGlazingProfileMappingRepresentation,
	ImportSillMappingRepresentation,
	ImportSluitpotMappingRepresentation,
	ImportStopProfileMappingRepresentation
} from '../../../../../modules/api-client/generated';


export const useGetImportSillMappings = (enabled: boolean = true) => {
	const response = useQuery<Array<ImportSillMappingRepresentation>, AxiosError, Array<ImportSillMappingRepresentation>>(
		['aclImportMappingsSillsGet'],
		() => {
			return ApiClient.Acl.ImportMapping.aclImportMappingSillsGet().then((res) => res.data);
		},
		{
			useErrorBoundary: true,
			enabled: enabled,
		}
	);

	if (response.error) {
		var error = response.error;
		toast.error(error?.message);
	}

	return response;
};

export const useGetImportCompartmentLayoutMappings = (enabled: boolean = true) => {
	const response = useQuery<Array<ImportCompartmentLayoutMappingRepresentation>, AxiosError, Array<ImportCompartmentLayoutMappingRepresentation>>(
		['aclImportMappingsCompartmentLayoutGet'],
		() => {
			return ApiClient.Acl.ImportMapping.aclImportMappingCompartmentLayoutsGet().then((res) => res.data);
		},
		{
			useErrorBoundary: true,
			enabled: enabled,
		}
	);

	if (response.error) {
		var error = response.error;
		toast.error(error?.message);
	}

	return response;
};

export const useGetImportStopProfileMappings = (enabled: boolean = true) => {
	const response = useQuery<Array<ImportStopProfileMappingRepresentation>, AxiosError, Array<ImportStopProfileMappingRepresentation>>(
		['aclImportMappingsStopProfileGet'],
		() => {
			return ApiClient.Acl.ImportMapping.aclImportMappingStopprofilesGet().then((res) => res.data);
		},
		{
			useErrorBoundary: true,
			enabled: enabled,
		}
	);

	if (response.error) {
		var error = response.error;
		toast.error(error?.message);
	}

	return response;
};
export const useGetImportGlazingProfilesMappings = (enabled: boolean = true) => {
	const response = useQuery<Array<ImportGlazingProfileMappingRepresentation>, AxiosError, Array<ImportGlazingProfileMappingRepresentation>>(
		['aclImportMappingsGlazingprofilesGet'],
		() => {
			return ApiClient.Acl.ImportMapping.aclImportMappingGlazingprofilesGet().then((res) => res.data);
		},
		{
			useErrorBoundary: true,
			enabled: enabled,
		}
	);

	if (response.error) {
		var error = response.error;
		toast.error(error?.message);
	}

	return response;
};
export const useGetImportGlazingBarsMappings = (enabled: boolean = true) => {
	const response = useQuery<Array<ImportGlazingBarMappingRepresentation>, AxiosError, Array<ImportGlazingBarMappingRepresentation>>(
		['aclImportMappingsGlazingbarGet'],
		() => {
			return ApiClient.Acl.ImportMapping.aclImportMappingGlazingbarsGet().then((res) => res.data);
		},
		{
			useErrorBoundary: true,
			enabled: enabled,
		}
	);
	if (response.error) {
		var error = response.error;
		toast.error(error?.message);
	}
	return response;
};
export const useGetImportCornerpieceMappings = (enabled: boolean = true) => {
	const response = useQuery<Array<ImportCornerPieceMappingRepresentation>, AxiosError, Array<ImportCornerPieceMappingRepresentation>>(
		['aclImportMappingsGlazingbarGet'],
		() => {
			return ApiClient.Acl.ImportMapping.aclImportMappingCornerpiecesGet().then((res) => res.data);
		},
		{
			useErrorBoundary: true,
			enabled: enabled,
		}
	);
	if (response.error) {
		var error = response.error;
		toast.error(error?.message);
	}
	return response;
};
