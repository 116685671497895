/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link } from "react-router-dom";
import { EkoTable } from "../../../../../shared/components/table/EkoTable";
import { TableHeader } from "../../../../../shared/components/table/TableHeader";
import { formatDate } from '../../../../../shared/components/date';
import { TransportPlanningByWeekView } from '../../../../../modules/api-client/generated';
import { EmRoutePath } from '../../EmRoutes';
import { useListTransportPlanningByWeek } from '../hooks/use-list-transport-planning-by-week-odata';
import Loading from "../../../../../shared/components/Loading";

const TransportPlanningByWeek: React.FC = () => {
    
    const {
        isInitialLoading,
        data: transportWeekList,
        isError
    } = useListTransportPlanningByWeek(undefined);

    if (isInitialLoading) {
        return <Loading />;
    }

    if (isError) {
        return <>Fout bij het ophalen van de gegevens!</>;
    }

    if (!transportWeekList || !transportWeekList.value) return <></>;

    return (
        <EkoTable className={'card-xxl-stretch mb-5 mb-xxl-8'}>
            <TableHeader>
                <th className='min-w-40px'>Week</th>                
                <th className='min-w-50px text-center'>Orders</th>
                <th className='min-w-50px text-center'>Aantal adressen</th>
                <th className='min-w-50px text-center'>Meters</th>
            </TableHeader>
            <tbody>
                {transportWeekList.value && transportWeekList.value.map((transportPlanningWeek: TransportPlanningByWeekView) =>
                    <tr key={transportPlanningWeek.plannedExpeditionYear + '' + transportPlanningWeek.plannedExpeditionWeek}>
                        <td>
                                                        
                            <Link
                            to={EmRoutePath.link(EmRoutePath.planningTransportWeekLink(transportPlanningWeek.plannedExpeditionYear, transportPlanningWeek.plannedExpeditionWeek))}
                            className='btn btn-primary btn-flex ps-5 pe-10'>
                                <span className="d-flex flex-column align-items-start ms-2">
                                    <span className="fs-3 fw-bold">
                                        {transportPlanningWeek.plannedExpeditionYear === 0 ? 'Ongepland' : transportPlanningWeek.plannedExpeditionYear + '-' + transportPlanningWeek.plannedExpeditionWeek}
                                    </span>
                                    <span className={'fs-7'}> {transportPlanningWeek.weekStartDate && formatDate(transportPlanningWeek.weekStartDate,'dd-MM')} - {transportPlanningWeek.weekEndDate && formatDate(transportPlanningWeek.weekEndDate, 'dd-MM')}</span>
                                </span>
                            </Link>
                        </td>
                        <td className="text-center">
                            {transportPlanningWeek.orderCount}
                        </td>
                        <td className="text-center">                            
                            {transportPlanningWeek.addressCount}
                        </td>
                        <td className="text-center">
                            {transportPlanningWeek.sillLength}
                        </td>
                    </tr>
                )}
            </tbody>
        </EkoTable>
    )
}

export { TransportPlanningByWeek }