import React from 'react';
import {useQuery} from '@tanstack/react-query';
import ApiClient from '../../../../modules/api-client/ApiClient';
import {Link, useParams} from 'react-router-dom';
import * as generated from '../../../../modules/api-client/generated';
import Loading from '../../../../shared/components/Loading';
import { formatDate } from '../../../../shared/components/date';

const ContentDetails : React.FC = () => {

	const {id} = useParams<{ id: string }>();

	const {isInitialLoading, data: details, isError} = useQuery<generated.ContentDetails>(['homepageContentDetailsGet'], () => ApiClient.Homepage.getContentDetails(id!).then((res) => res.data));

	if (isInitialLoading) {
		return <Loading />;
	}

	if (isError) {
		return <>ERROR!</>;
	}

	if (!details) return <></>;

	return (
		<div className='card mb-5'>
			<div className='card-header'>
				<h3 className='card-title'>{details.contentType} {details.code}</h3>
				<div className='card-toolbar'>
					<Link to='edit' relative='path' className='btn btn-primary ms-2'>
						Content wijzigen
					</Link>
				</div>
			</div>

			<div className='card-body position-relative'>
				<div className='row'>
					{details.contentType.toUpperCase() === generated.ContentType.NewsItem.toUpperCase() && (
					<div className='col-3'>
						<div style={{height: '200px', width: '100%'}}>
							<img src="https://picsum.photos/seed/picsum/200/200" alt="Test"/>
						</div>
					</div>)}
					<div className='col'>
						<div className='row mb-4'>
							<div className='col-3'>
								<label className='form-label'>Code</label>
							</div>
							<div className='col'>{details.code}</div>
						</div>
						<div className='row mb-4'>
							<div className='col-3'>
								<label className='form-label'>ContentType</label>
							</div>
							<div className='col'>{details.contentType}</div>
						</div>
						<div className='row mb-4'>
							<div className='col-3'>
								<label className='form-label'>Message</label>
							</div>
							<div className='col'>{details.message}</div>
						</div>
						{details.contentType.toUpperCase() === generated.ContentType.NewsItem.toUpperCase() && (<div className='row mb-4'>
							<div className='col-3'>
								<label className='form-label'>Aangemaakt op</label>
							</div>
							<div className='col'>{formatDate(details.createdAt!)}</div>
						</div>)}
						{details.contentType.toUpperCase() === generated.ContentType.Notification.toUpperCase() && (<div className='row mb-4'>
							<div className='col-3'>
								<label className='form-label'>Geldig van</label>
							</div>
							<div className='col'>{formatDate(details.validFrom!)}</div>
						</div>)}
						{details.contentType.toUpperCase() === generated.ContentType.Notification.toUpperCase() && (<div className='row mb-4'>
							<div className='col-3'>
								<label className='form-label'>Geldig tot</label>
							</div>
							<div className='col'>{formatDate(details.validTo!)}</div>
						</div>)}
					</div>
				</div>
			</div>
		</div>)
};

export default ContentDetails;
