/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react';
import {Outlet, useParams} from "react-router-dom";
import {
    useGetCustomerImportedProfileMappings
} from "../../../../backoffice/modules/acl/import/hooks/use-get-customer-imported-profile-mappings";
import {
    useGetCustomerSluitpotMappings
} from "../../../../backoffice/modules/acl/import/hooks/use-get-customer-sluitpot-mappings";
import {
    ImportedProfileMappingRepresentation,
    ImportSluitpotMappingRepresentation,
    MappingKind
} from "../../../../modules/api-client/generated";
import {EkoTable, TableHeader} from "../../../components/table";
import {useEkosietAuth0} from "../../../../modules/auth0/core/useEkosietAuth0";
import SpinnerButton from "../../../components/SpinnerButton";
import {
    useDeleteImportProfileMapping
} from "../../../../backoffice/modules/acl/import/hooks/use-delete-import-profile-mapping";
import {
    useDeleteImportSluitpotMapping
} from "../../../../backoffice/modules/acl/import/hooks/use-delete-import-sluitpot-mapping";
import ImportSluitpotMappingUpsert from "../../../../backoffice/modules/acl/import/components/ImportSluitpotMappingUpsert";
import {Button, Modal} from "react-bootstrap";
import {ImportModal} from "../../components/ImportModal";
import {EkoCard, EkoCardBody, EkoCardHeader, EkoCardToolbar} from "../../../components/card";
import Loading from "../../../components/Loading";


const CustomerImportSluitpotMappingPage: React.FC = () => {

    const {id} = useParams<{ id: string }>();
    const {isKlant, customerId} = useEkosietAuth0();

    const {
        isInitialLoading,
        data: sluitpotMappings,
        isError
    } = useGetCustomerSluitpotMappings(isKlant ? customerId : id);
    const [selectedSluitpotMapping, setSelectedSluitpotMapping] = useState<ImportSluitpotMappingRepresentation | undefined>(undefined);
    const [showModal, setShowModal] = useState<boolean>(false);
    var mutationDeleteImportMapping = useDeleteImportSluitpotMapping();

    if (isInitialLoading) {
        return <Loading />;
    }

    if (isError) {
        return <>Fout bij het ophalen van de sluitpot koppelingen!</>;
    }

    return (
        <>
            <ImportModal show={showModal} handleClose={() => setShowModal(false)}
                         title={'Mapping toevoegen of aanpassen'}
                         size={'lg'}
                         component={
                             <ImportSluitpotMappingUpsert
                                 id={selectedSluitpotMapping?.id}
                                 customerId={selectedSluitpotMapping?.customer.id ?? isKlant ? customerId : id}
                                 handleSucces={
                                     () => {setSelectedSluitpotMapping(undefined); setShowModal(false)}
                                 }
                             />
                         }
                         fullscreen={'md-down'}/>
            <EkoCard>
                <EkoCardHeader title={'Sluitpot koppelingen'} >
                    <EkoCardToolbar>
                        <Button className={'btn btn-primary'} onClick={()=>setShowModal(true)}>Toevoegen</Button>
                    </EkoCardToolbar>
                </EkoCardHeader>
                <EkoCardBody>
                    {(!sluitpotMappings) ?
                        <div className={'alert alert-warning'}>
                            Er zijn nog geen profielen geïmporteerd voor deze klant.
                        </div>
                        :
                        <EkoTable>
                            <TableHeader>
                                <th>Externe code</th>
                                <th></th>
                                <th>Ekosiet sluitpot</th>
                            </TableHeader>
                            <tbody>
                            {sluitpotMappings.map((spm) => (
                                <tr key={spm.id}>
                                    <td><h2>{spm.externalCode}</h2></td>
                                    <td><h1><i className={'fa fa-arrow-right'}> </i></h1></td>
                                    <td><h2>{spm.sluitpotBeslag.name}</h2></td>
                                    <td>
                                        <button
                                            type="button"
                                            className={`btn btn-light-primary btn-sm btn-light me-2 `}
                                            title="Wijzig Mapping"
                                            onClick={() => {
                                                setSelectedSluitpotMapping(spm);
                                                setShowModal(true);
                                            }}
                                        >
                                            <i className="fas fa-edit"></i> Aanpassen
                                        </button>
                                        <SpinnerButton
                                            className={`btn btn-light-danger`}
                                            title="Verwijder mapping"
                                            onClick={async () => {
                                                await mutationDeleteImportMapping.mutateAsync(spm.id);
                                            }}
                                        >
                                            <i className="fas fa-trash"></i> Ontkoppelen
                                        </SpinnerButton>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </EkoTable>
                    }
                </EkoCardBody>
            </EkoCard>
        </>
    );
};

export {CustomerImportSluitpotMappingPage};
