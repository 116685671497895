/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {ReactElement, useEffect, useState} from 'react'
import {Link, useNavigate, useParams} from "react-router-dom";
import {EkoCard, EkoCardBody} from "../../../../../../_metronic/helpers";
import {EkoTable} from "../../../../../shared/components/table/EkoTable";
import {dateToString, formatDate} from '../../../../../shared/components/date';
import {useListOrderExpeditionsByWeekOdata} from '../hooks/use-list-order-expeditions-by-week-odata';
import {NumericFormat} from "react-number-format";
import {
    ProductionItemStationStatus,
    ProductionItemStatus
} from "../../../../../shared/components/ProductionItemStatus";
import {
    usePutExpeditionDelivered,
    usePutExpeditionReadyForShipment
} from "../../../sm/orders/hooks/use-put-expedition-status";
import ConfiguredSillSpecification from "../../../../../shared/components/ConfiguredSillSpecification";
import {DetailModal} from "./DetailModal";
import classNames from "classnames";
import AfmontageSpecification from '../../../pm/components/AfmontageSpecification';
import {
    ProductionItemRepresentation,
    PutProductionItemStatusRepresentation,
    ExpeditionPlanningByOrderView,
    ExpeditionStatusCodes,
    ProductionItemOperations,
    PutProductionItemOnPalletRepresentation
} from '../../../../../modules/api-client/generated';
import {generateDymoLabel} from '../../../../components/LabelPrinter';
import {printLabel} from '../../../../../shared/utils/react-dymo';
import {usePutProductionItemFinished} from '../../../pm/hooks/use-put-production-item-finished';
import {
    useOrderIdGetByBarCode,
    useProductionItemGetByBarCode,
    useProductionItemGetId
} from '../../../pm/hooks/use-get-production-item';
import {EkoCardToolbar} from '../../../../../shared/components/card/EkoCardToolbar';
import {PackingSlipsDocument} from './PackingSlipsDocument';
import {toast} from "react-hot-toast";
import BarcodeReader from "react-barcode-reader";
import {PageTitle} from '../../../../../../_metronic/layout/core';
import {Form, Formik} from "formik";
import TextField from '../../../../../shared/components/TextField';
import FieldErrors from '../../../../../shared/components/FieldErrors';
import * as Yup from "yup";
import {EmRoutePath} from "../../EmRoutes";
import {useGetExpeditionItems} from '../hooks/use-get-expedition-items';
import {TableHeader} from '../../../../../shared/components/table/TableHeader';
import {OrderItems} from "../../pages/Expedition";
import {usePutProductionItemOnPallet} from "../../../pm/hooks/use-put-production-item-on-pallet";
import Loading from "../../../../../shared/components/Loading";

const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
    list.reduce((previous, currentItem) => {
        const group = getKey(currentItem);
        if (!previous[group]) previous[group] = [];
        previous[group].push(currentItem);
        return previous;
    }, {} as Record<K, T[]>);

export interface ExpeditionSillDetailsByIdProps {
    catalogItemId: string,
    productionItemId: string,
    plannedExpeditionDate?: string | undefined,
    handleClose: () => void,
}

export interface ExpeditionSillDetailsByCodeProps {
    productionItemCode: string,
    plannedExpeditionDate?: string | undefined,
    handleClose: () => void,
}

export interface OrderLineDetailProps {
    orderId: string,
    handleClose: () => void,
}

export interface ExpeditionSillDetailsProps {
    plannedExpeditionDate?: string | undefined,
    catalogItemId: string | undefined,
    productionItem: ProductionItemRepresentation | undefined,
    handleClose: () => void,
}

export interface ExpeditionItemsProps {
    orderId: string
}

export const ExpeditionItems: React.FC<ExpeditionItemsProps> = ({
                                                                    orderId
                                                                }) => {

    const {
        isInitialLoading,
        data,
        isError
    } = useGetExpeditionItems(orderId);

    if (isInitialLoading) {
        return <Loading />;
    }

    if (isError) {
        return <>Fout bij het ophalen van de gegevens!</>;
    }

    if (!data) return <></>;

    return (
        <>
            <EkoTable>
                <TableHeader>
                    <th>Aantal</th>
                    <th>Omschrijving</th>
                </TableHeader>
                <tbody>
                {data && data.lines && data.lines.filter(l => l.type !== 'shippingCostLine' && l.type !== 'configurationLine').map(line =>
                    <tr key={line.id}>
                        <td>{line.quantity}</td>
                        <td>{line.title}</td>
                    </tr>
                )}
                {data && data.mountings && data.mountings.filter(mounting => mounting.quantity > 0).map(mounting =>
                    <tr key={mounting.id}>
                        <td>{mounting.quantity}</td>
                        <td>{mounting.name}</td>
                    </tr>
                )}
                </tbody>
            </EkoTable>
        </>
    )
}

export const OrderLineDetail: React.FC<OrderLineDetailProps> = ({
                                                                    orderId,
                                                                    handleClose
                                                                }) => {
    const readyForShipmentMutation = usePutExpeditionReadyForShipment()

    const setReadyForShipment = (orderId: string) => {
        return new Promise<void>(() => {
            readyForShipmentMutation.mutate(orderId, {});
            handleClose();
        }).finally();
    }

    return (
        <>
            <h1></h1>
            <p className={'alert alert-info'}>
                Let op: heb je alle losse items en de pakbon bijgevoegd?
            </p>

            <ExpeditionItems orderId={orderId}/>

            <button className={`btn btn-success`}
                    onClick={() => setReadyForShipment(orderId)}>
                Verzendklaar melden
            </button>
        </>
    )
}

export const ExpeditionSillDetailsById: React.FC<ExpeditionSillDetailsByIdProps> = ({
                                                                                        catalogItemId,
                                                                                        productionItemId,
                                                                                        plannedExpeditionDate,
                                                                                        handleClose
                                                                                    }) => {
    const {data: pi} = useProductionItemGetId(productionItemId)

    return (
        <>
            <ExpeditionSillDetails catalogItemId={catalogItemId} productionItem={pi} handleClose={handleClose}
                                   plannedExpeditionDate={plannedExpeditionDate}/>
        </>
    )
}

export const ExpeditionSillDetailsByCode: React.FC<ExpeditionSillDetailsByCodeProps> = ({
                                                                                            productionItemCode,
                                                                                            plannedExpeditionDate,
                                                                                            handleClose
                                                                                        }) => {

    const {data: pi} = useProductionItemGetByBarCode(productionItemCode)

    return (
        <>
            <ExpeditionSillDetails catalogItemId={pi?.catalogItem?.id} productionItem={pi} handleClose={handleClose}
                                   plannedExpeditionDate={plannedExpeditionDate}/>
        </>
    )
}

export const ExpeditionSillDetails: React.FC<ExpeditionSillDetailsProps> = ({
                                                                                catalogItemId,
                                                                                plannedExpeditionDate,
                                                                                productionItem,
                                                                                handleClose
                                                                            }) => {

    const finishedMutation = usePutProductionItemFinished()
    const onPalletMutation = usePutProductionItemOnPallet()

    const printDymoLabel = async (id: string, withSticker: boolean = false) => {
        const labelXml = generateDymoLabel(productionItem!, plannedExpeditionDate!);
        const response = await printLabel("DYMO LabelWriter Wireless on DYMOLWW12C1DA", labelXml, "");
        if (response.status == 200) {
            console.log('Label geprint');
        } else {
            console.log('Label niet geprint');

        }
        handleClose();
    }
    const finish = async (id: string, withSticker: boolean = false) => {

        const labelXml = generateDymoLabel(productionItem!, plannedExpeditionDate!);

        const response = await printLabel("DYMO LabelWriter Wireless on DYMOLWW12C1DA", labelXml, "");

        if (response.status == 200) {
            console.log('Label geprint');
        } else {
            console.log('Label niet geprint');

        }

        const command: PutProductionItemStatusRepresentation = {
            productionItemIds: [id],
        }
        const commandPallet: PutProductionItemOnPalletRepresentation = {
            productionItemIds: [id],
            onPallet: true,
        }

        await finishedMutation.mutateAsync(command, {});
        await onPalletMutation.mutateAsync(commandPallet, {});

        handleClose();
    }

    return (
        <>
            <EkoCard>
                <div className='card-header'>
                    {productionItem &&
                        <>
                            <div className='card-title m-0 d-flex flex-column'>
                                <Link
                                    to={EmRoutePath.link(EmRoutePath.expeditionLink(productionItem.order?.id!))}
                                    className={classNames("h2 w-bolder m-0")}>
                                    {productionItem.order?.code!} | {productionItem.customer?.name} | {productionItem.merk}
                                </Link>
                                {/*<h2 className='fw-bolder m-0'>{productionItem.order?.code} | {productionItem.customer?.name} | {productionItem.merk}</h2>*/}
                                <span>Productiedatum: {formatDate(productionItem.plannedProductionDate)}</span>
                                <span>Leverdatum: {plannedExpeditionDate!}</span>
                            </div>
                            <EkoCardToolbar>
                                <button
                                    className="btn btn-info me-3"
                                    type="button"
                                    onClick={() => {
                                        printDymoLabel(productionItem.id, true);
                                    }}
                                    aria-expanded="false">
                                    Print label
                                </button>
                                <button
                                    className="btn btn-success"
                                    type="button"
                                    onClick={() => {
                                        if (productionItem?.instructions) {
                                            if (window.confirm('Heb je de instructies gelezen?')) {
                                                finish(productionItem.id, true)
                                            }
                                        } else {
                                            finish(productionItem.id, true);
                                        }
                                    }}
                                    aria-expanded="false">
                                    Gereedmelden
                                </button>
                            </EkoCardToolbar>
                        </>
                    }
                </div>
                <EkoCardBody>
                    {productionItem && catalogItemId &&
                        <AfmontageSpecification id={catalogItemId} instruction={productionItem.instructions!}
                                                remark={productionItem.remark!}/>
                    }

                    {catalogItemId &&
                        <ConfiguredSillSpecification catalogItemId={catalogItemId}/>
                    }

                </EkoCardBody>
            </EkoCard>
        </>
    )
}

type InputValue = {
    code: string
}
const formSchema = Yup.object().shape({
    code: Yup.string().length(9, 'Voer een 9 cijferige dorpelcode in.').required('Voer een 9 cijferige dorpelcode in.')
});

const $statusFilter = "(ExpeditionStatus eq 'NotPlanned' or ExpeditionStatus eq 'Planned' or ExpeditionStatus eq 'ReadyForShipment')"

const ExpeditionWeekTable: React.FC = () => {
    const [showModal, setShowModal] = useState<boolean>(false)
    const [modalFullscreen, setModalFullscreen] = useState<true | string | 'sm-down' | 'md-down' | 'lg-down' | 'xl-down' | 'xxl-down'>('xxl-down')
    const [modalTitle, setModalTitle] = useState<string | undefined | null>(null);
    const [modalComponent, setModalComponent] = useState<ReactElement | undefined | null>(null);

    var navigate = useNavigate();
    const {year, week} = useParams<{ year: string, week: string }>();

    let pageTitle = ''
    if (year === '0') {
        pageTitle = `Expeditie planning - Ongepland`
    } else {
        pageTitle = `Expeditie planning - Week ${year}-${week}`
    }

    const $filter = $statusFilter + `and PlannedExpeditionYear eq ${year} and PlannedExpeditionWeek eq ${week}`

    const afgehaaldMutation = usePutExpeditionDelivered();

    const {
        isInitialLoading,
        data: expeditionOrderWeekList,
        isError
    } = useListOrderExpeditionsByWeekOdata($filter);

    if (isInitialLoading) {
        return <Loading />;
    }

    if (isError) {
        return <>Fout bij het ophalen van de gegevens!</>;
    }

    if (!expeditionOrderWeekList || !expeditionOrderWeekList.value) return <></>;

    let sorted = expeditionOrderWeekList.value.sort((a: ExpeditionPlanningByOrderView, b: ExpeditionPlanningByOrderView) => {

        let bDate = (b.plannedExpeditionDate ? new Date(b.plannedExpeditionDate) : new Date());
        let aDate = (a.plannedExpeditionDate ? new Date(a.plannedExpeditionDate) : new Date());
        let x = +aDate - +bDate;
        return x;
    })

    let itemsByOrderAndDate = groupBy(sorted, (eow: ExpeditionPlanningByOrderView) => eow.plannedExpeditionDate || '' + eow.orderCode || '')

    const setAfgehaaldMutation = (orderId: string) => {
        return new Promise<void>(() => {
            afgehaaldMutation.mutate(orderId, {});
        }).finally();
    }

    const readyForShipmentModal = (orderId: string) => {
        setModalComponent(
            <OrderLineDetail orderId={orderId} handleClose={() => setShowModal(false)}/>
        );
        setModalTitle('Order gereed melden');
        setModalFullscreen('xxl-down');
        setShowModal(true);
    }

    const viewSillDetailsByBarCode = (barCode: string) => {
        setModalComponent(<>
            <ExpeditionSillDetailsByCode productionItemCode={barCode} handleClose={() => setShowModal(false)}/>
        </>);
        setModalTitle('Details');
        setModalFullscreen('xxl-down');
        setShowModal(true);
    }

    const viewSillDetailsById = (catalogItemId: string, productionItemId: string) => {
        setModalComponent(<>
            <ExpeditionSillDetailsById catalogItemId={catalogItemId} productionItemId={productionItemId}
                                       handleClose={() => setShowModal(false)}/>
        </>);
        setModalTitle('Details');
        setModalFullscreen('xxl-down');
        setShowModal(true);
    }

    var openDocument = (orderIds: string[], fileName: string) => {
        setModalComponent(() => <PackingSlipsDocument orderIds={orderIds} fileName={`pakbonnen-${fileName}`}/>)
        setModalTitle('Document')
        setShowModal(true)
    }

    const handleScan = async (code: string) => {

        var reg = /^\d+$/;

        if (!reg.test(code) || code.length !== 9) {
            handleScanError('Scan een EDS barcode!')
            return;
        }

        if (code.length === 9) {
            handleEdsBarCodeScan(code);
            return;
        }

        handleScanError('Scan een EDS barcode!')
        return;
    }

    const handleEdsBarCodeScan = (code: string) => {

        toast.success(
            `EDS barcode gescanned: ${code}`,
            {
                duration: 3000
            }
        );
        navigate(EmRoutePath.link(EmRoutePath.expeditionByProductionItemLink(code)));
    }

    const handleScanError = (err: string) => {
        toast.error(
            `Scan error: '${err}'`,
            {
                duration: 3000
            }
        );
    }

    const initValues: InputValue = {
        code: ''
    }

    function handleInput(data: InputValue) {
        navigate(EmRoutePath.link(EmRoutePath.expeditionByProductionItemLink(data.code)));
    }

    return (<>

            <PageTitle toolbar={
                <>
                    <div className={'d-flex align-items-center justify-content-center'}>
                        <>Scan een EDS barcode of</>
                        <Formik initialValues={initValues} onSubmit={handleInput} validationSchema={formSchema}>
                            {({
                                  values,
                              }) => (
                                <Form>
                                    <div className={'ms-3'}>
                                        <div className={'input-group d-flex align-items-start'}>
                                            <TextField name='code'
                                                       hideFieldErrors={true}
                                                       className={'form-control form-control-sm'}
                                                       placeholder={'9-cijferige EDS dorpel code'}
                                                       value={values.code}
                                            />
                                            <button
                                                type='submit'
                                                className='btn btn-sm btn-primary'>
                                                <span className='indicator-label'>Zoek dorpel</span>
                                            </button>
                                        </div>
                                        <FieldErrors field={'code'}/>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </>
            }>
                {pageTitle}
            </PageTitle>


            <BarcodeReader
                onError={handleScanError}
                onScan={handleScan}
            />

            <DetailModal show={showModal} handleClose={() => setShowModal(false)}
                         title={modalTitle}
                         size={'xl'}
                         fullscreen={modalFullscreen}
                         component={modalComponent}
            />
            <div className="accordion" id="accordionExample">

                {itemsByOrderAndDate && Object.entries(itemsByOrderAndDate).map((groupedItem, index) => {

                    const group = groupedItem[1]
                    const firstItem = group[0]
                    const itemsByOrder = groupBy(group, eow => eow.orderCode || '')
                    const orderEntries = Object.entries(itemsByOrder)
                    const orderIds = orderEntries.map((s) => s[1]).map(x => x[0].orderId || '').flat().filter(x => x !== '' && x !== null);

                    return (
                        <>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <div className="accordion-button" data-bs-toggle="collapse"
                                         data-bs-target={'#' + firstItem.orderCode} aria-expanded="true"
                                         aria-controls={firstItem.orderCode!}>
                                        <div className="d-flex justify-content-between align-items-center w-100">
                                            <h3 className="">
                                                {firstItem.plannedExpeditionDate && dateToString(firstItem.plannedExpeditionDate)}
                                            </h3>
                                            <div className={'me-5'}>

                                                <span className={'fs-5'}>
                                                    {orderEntries && orderEntries.filter((s) => s[1][0].expeditionStatus === 'planned').length}
                                                </span>
                                                <span className={'fs-5 text-muted'}>
                                                    /
                                                </span>
                                                <span className={'fs-4'}>
                                                    {orderEntries && orderEntries.length} order{orderEntries && orderEntries.length > 1 ? 's' : ''}</span>
                                                <button className={'btn btn-light-primary btn-sm ms-5'}
                                                        onClick={() => openDocument(orderIds, formatDate(firstItem.plannedExpeditionDate || ''))}>Print
                                                    pakbonnen
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                </h2>
                                <div id={firstItem.orderCode!} className={`collapse show`}
                                     data-bs-parent="#accordionExample">
                                    <div className={'accordion-body scroll mh-750px'}>

                                        {orderEntries && orderEntries.map((itemsForOrder) => {

                                            const orderKey = itemsForOrder[0]
                                            const productionItems = itemsForOrder[1]
                                            const firstProductionItem = productionItems[0]
                                            const rowClassName = classNames(firstProductionItem.expeditionStatus !== 'planned' ? 'text-decoration-line-through text-muted' : '')

                                            return (
                                                <>
                                                    <EkoTable className={'card-xxl-stretch'}>
                                                        <colgroup>
                                                            <col width={'15%'}/>
                                                            <col width={'25%'}/>
                                                            <col width={'20%'}/>
                                                            <col width={'10%'}/>
                                                            <col width={'10%'}/>
                                                            <col width={'20%'}/>
                                                        </colgroup>
                                                        <tbody>
                                                        <tr key={orderKey}>
                                                            <td className={rowClassName}>
                                                                <Link
                                                                    to={EmRoutePath.link(EmRoutePath.expeditionLink(firstProductionItem.orderId!))}
                                                                    className={classNames("text-hover-primary fs-6")}>
                                                                    {firstProductionItem.orderCode}
                                                                </Link>
                                                            </td>
                                                            <td className={rowClassName}>
                                                                <span className='fs-6'>
                                                                    {firstProductionItem.customerCompanyName}
                                                                </span>
                                                            </td>
                                                            <td className={rowClassName}>
                                                                {firstProductionItem.orderDeliveryCity ? firstProductionItem.orderDeliveryCity : 'Onbekend'}
                                                            </td>
                                                            <td className={rowClassName}>

                                                                {firstProductionItem.maxSillLengthInOrder && firstProductionItem.maxSillLengthInOrder > 0 &&
                                                                    <>
                                                                        Langste dorpel:&nbsp;
                                                                        <NumericFormat
                                                                            className={'me-1 text-nowrap fs-4'}
                                                                            decimalScale={2}
                                                                            value={firstProductionItem.maxSillLengthInOrder}
                                                                            displayType={'text'}
                                                                            thousandSeparator={true} suffix={' m'}/>
                                                                    </>
                                                                }
                                                            </td>
                                                            <td className={rowClassName}>
                                                                {firstProductionItem.delivery ? "Bezorging" : 'Afhalen!'}
                                                            </td>
                                                            <td className={'d-flex justify-content-end'}>
                                                                {firstProductionItem.expeditionStatus === ExpeditionStatusCodes.Planned &&
                                                                    <button className={`btn btn-info`}
                                                                            onClick={() => readyForShipmentModal(firstProductionItem.orderId!)}>
                                                                        Verzendklaar melden
                                                                    </button>
                                                                }

                                                                {firstProductionItem.expeditionStatus === ExpeditionStatusCodes.ReadyForShipment
                                                                    && !firstProductionItem.delivery &&
                                                                    <button className={`btn btn-success`}
                                                                            onClick={() => setAfgehaaldMutation(firstProductionItem.orderId!)}>
                                                                        Afgehaald melden
                                                                    </button>
                                                                }
                                                            </td>
                                                        </tr>

                                                        {firstProductionItem.expeditionStatus === ExpeditionStatusCodes.Planned &&
                                                            <tr key={orderKey + '-items'}>
                                                                <td colSpan={6}>
                                                                    <EkoTable className={'card-xxl-stretch '}>
                                                                        <colgroup>
                                                                            <col width={'25%'}/>
                                                                            <col width={'15%'}/>
                                                                            <col width={'20%'}/>
                                                                            <col width={'20%'}/>
                                                                            <col width={'20%'}/>
                                                                        </colgroup>
                                                                        <tbody>
                                                                        {(!productionItems || !firstProductionItem.productionItemId) &&
                                                                            <tr key={'empty-production-items' + firstProductionItem.orderId}>
                                                                                <td colSpan={5}>Order zonder productie
                                                                                    items!
                                                                                </td>
                                                                            </tr>
                                                                        }

                                                                        {productionItems && firstProductionItem.productionItemId && productionItems.map((productionItem) => {
                                                                            return (
                                                                                <tr key={'pi-' + productionItem.productionItemId}>
                                                                                    <td>
                                                                                        <a className='text-hover-primary fs-6'
                                                                                           href={'#'}
                                                                                           onClick={() => viewSillDetailsById(productionItem.catalogItemId || '', productionItem.productionItemId || '')}>
                                                                                            {productionItem.catalogItemTitle}
                                                                                        </a>
                                                                                    </td>
                                                                                    <td>
                                                                                        {productionItem.productionItemCode}{productionItem.productionItemSequence}
                                                                                    </td>
                                                                                    <td>
                                                                                        {productionItem.productionItemMerk}
                                                                                    </td>
                                                                                    <td>
                                                                                        {productionItem.plannedProductionDate && formatDate(productionItem.plannedProductionDate)}
                                                                                    </td>
                                                                                    <td className={'d-flex justify-content-end text-center align-items-center'}>
                                                                                        <ProductionItemStationStatus
                                                                                            title={ProductionItemOperations.NeutenGemaakt}
                                                                                            status={productionItem.neutenGemaakt}/>
                                                                                        <ProductionItemStationStatus
                                                                                            title={ProductionItemOperations.LattenGemaakt}
                                                                                            status={productionItem.lattenGemaakt}/>
                                                                                        <ProductionItemStationStatus
                                                                                            title={ProductionItemOperations.DorpelProfielGezaagd}
                                                                                            status={productionItem.dorpelProfielGezaagd}/>
                                                                                        <ProductionItemStationStatus
                                                                                            title={ProductionItemOperations.DorpelBewerkt}
                                                                                            status={productionItem.dorpelBewerkt}/>
                                                                                        <ProductionItemStationStatus
                                                                                            title={ProductionItemOperations.NeutenGemonteerd}
                                                                                            status={productionItem.neutenGemonteerd}/>
                                                                                        <ProductionItemStationStatus
                                                                                            title={ProductionItemOperations.SluitpottenGemonteerd}
                                                                                            status={productionItem.sluitpottenGemonteerd}/>
                                                                                        <ProductionItemStationStatus
                                                                                            title={ProductionItemOperations.LattenGemonteerd}
                                                                                            status={productionItem.lattenGemonteerd}/>
                                                                                        <ProductionItemStationStatus
                                                                                            title={ProductionItemOperations.KopisolatorenGemonteerd}
                                                                                            status={productionItem.kopisolatorenGemonteerd}/>
                                                                                        <ProductionItemStationStatus
                                                                                            title={ProductionItemOperations.ManchettesGemonteerd}
                                                                                            status={productionItem.manchettesGemonteerd}/>
                                                                                        <ProductionItemStationStatus
                                                                                            title={ProductionItemOperations.HefschuifVouwwand}
                                                                                            status={productionItem.hefschuifVouwwand}/>
                                                                                        <ProductionItemStationStatus
                                                                                            title={ProductionItemOperations.Ingepakt}
                                                                                            status={productionItem.ingepakt}/>
                                                                                        <div className={'ms-3'}>
                                                                                            <ProductionItemStatus
                                                                                                status={productionItem.productionItemStatus}/>
                                                                                        </div>
                                                                                            <div className={'ms-3'}>
                                                                                                {productionItem.onPallet ?
                                                                                                    <i className="fa-solid fa-check-to-slot text-success"></i>
                                                                                                    :
                                                                                                    <i className="fa-solid fa-pallet text-danger"></i>
                                                                                                }
                                                                                            </div>
                                                                                    </td>
                                                                                </tr>
                                                                        )
                                                                        })}
                                                                        <OrderItems
                                                                            orderId={firstProductionItem.orderId}
                                                                            showOnlyNonProductionItems={true}
                                                                            showHeader={false}/>
                                                                        </tbody>
                                                                    </EkoTable>
                                                                </td>
                                                            </tr>
                                                        }
                                                        </tbody>
                                                    </EkoTable>
                                                </>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                })}
            </div>

        </>
    )
}

export {ExpeditionWeekTable}