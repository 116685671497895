import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {ErrorBoundary, FallbackProps} from "react-error-boundary";

import React from "react";
import axios from "axios";
import {CustomersPage} from "./pages/CustomersPage";
import {BackofficeRoutePath} from "../../RoutePath";
import {ArchivedCustomersPage} from './pages/ArchivedCustomersPage';
import {CustomerPage} from "./pages/CustomerPage";
import {CustomerEdit} from "./pages/CustomerCard/CustomerEdit";
import {CustomerAddresses} from "./pages/CustomerCard/CustomerAddresses";
import {CustomerOrders} from "./pages/CustomerCard/CustomerOrders";
import {CustomerStatistics} from "./pages/CustomerCard/CustomerStatistics";
import {CustomerInvoicing} from "./pages/CustomerCard/CustomerInvoicing";
import {CustomerActivity} from "./pages/CustomerCard/CustomerActivity";
import {CustomerUsers} from "./pages/CustomerCard/CustomerUsers";
import {CustomerPricelist} from "./pages/CustomerCard/CustomerPricelist";
import {CustomerImport} from "./pages/CustomerCard/CustomerImport";
import {CustomerImportProfileMapperPage} from "../../../shared/import/pages/mappings/CustomerImportProfileMapperPage";
import {CustomerImportProfileMappingPage} from "../../../shared/import/pages/mappings/CustomerImportProfileMappingPage";
import {CustomerImportSluitpotMappingPage} from "../../../shared/import/pages/mappings/CustomerImportSluitpotMappingPage";

export const CrmRoutePath = {
    customers: 'customers',
    archivedCustomers: 'customers/archived',
    customer: 'customer/:id',

        customerEdit: 'edit',
        customerAddresses: 'addresses',
        customerOrders: 'orders',
        customerAnalytics: 'analytics',
        customerUsers: 'users',
        customerPricelist: 'pricelist',
        customerInvoices: 'invoices',
        customerActivity: 'activity',

        customerImport: 'import',
        customerImportProfileMapping: 'profile-mapping',

        customerImportProfileMapper: 'map/:gaProfileId',

        customerImportSluitpotMapping: 'sluitpot-mapping',
        customerImportSluitpotMapper: 'sluitpot-mapper/:gaSluitpotId',

    customerLink: (id: string) => {
        return CrmRoutePath.customer.replace(':id', id);
    },
    customerImportProfileMapperLink: (gaProfileId: string) => {
        return CrmRoutePath.customerImportProfileMapper.replace(':gaProfileId', gaProfileId);
    },
    link(path: string) {
        return BackofficeRoutePath.crm + path;
    }

};

const CrmRoutes = () => {
    return (
        <Routes>
            <Route element={<ErrorBoundary FallbackComponent={Fallback}><Outlet/></ErrorBoundary>}>
                <Route path={CrmRoutePath.customers} element={<CustomersPage/>}/>
                <Route path={CrmRoutePath.archivedCustomers} element={<ArchivedCustomersPage/>}/>

                <Route path={CrmRoutePath.customer}
                       element={<ErrorBoundary FallbackComponent={Fallback}><CustomerPage/></ErrorBoundary>}>
                    <Route path={CrmRoutePath.customerEdit} element={<CustomerEdit/>}/>
                    <Route path={CrmRoutePath.customerAddresses} element={<CustomerAddresses/>}/>
                    <Route path={CrmRoutePath.customerOrders} element={<CustomerOrders/>}/>
                    <Route path={CrmRoutePath.customerAnalytics} element={<CustomerStatistics/>}/>
                    <Route path={CrmRoutePath.customerUsers} element={<CustomerUsers/>}/>
                    <Route path={CrmRoutePath.customerPricelist} element={<CustomerPricelist/>}/>
                    <Route path={CrmRoutePath.customerInvoices} element={<CustomerInvoicing/>}/>
                    <Route path={CrmRoutePath.customerActivity} element={<CustomerActivity/>}/>
                    <Route path={CrmRoutePath.customerImport} element={<><CustomerImport/></>}>
                        <Route path={CrmRoutePath.customerImportProfileMapping} element={<CustomerImportProfileMappingPage/>}>
                        <Route path={CrmRoutePath.customerImportProfileMapper} element={<CustomerImportProfileMapperPage/>}/>
                        </Route>
                        <Route path={CrmRoutePath.customerImportSluitpotMapping} element={<CustomerImportSluitpotMappingPage/>}/>
                        <Route path={CrmRoutePath.customerImportSluitpotMapper} element={<>TODO</>}/>
                        <Route index element={<Navigate to={CrmRoutePath.customerImportProfileMapping}/>}/>
                    </Route>
                    <Route index element={<CustomerEdit/>}/>
                </Route>

            </Route>
            <Route index element={<Navigate to={CrmRoutePath.customers}/>}/>
        </Routes>
    )
}

const Fallback: React.FC<FallbackProps> = (props) => {

    if (axios.isAxiosError(props.error)) {
        if (props.error.response?.status === 404) {
            return <>Not found</>;
        }
        if (props.error.response?.status === 500) {
            return <>Server error</>;
        }
    }

    return <>Error</>;
};

export default CrmRoutes
