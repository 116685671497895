/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react';
import {Link, Outlet, useLocation, useParams} from "react-router-dom";
import {useEkosietAuth0} from "../../../../modules/auth0/core/useEkosietAuth0";
import {ImportRoutePath} from "../../routes/ImportRoutes";
import {PageTitle} from "../../../../../_metronic/layout/core";


export const ImportMappingPage: React.FC = () => {

    const {isKlant} = useEkosietAuth0();

    return (
        <>
            <PageTitle>Import koppelingen beheren</PageTitle>
            <div className={'row'}>
                <div className={'col-2'}>
                    <div className='app-sidebar-menu overflow-hidden flex-column-fluid'>
                        <div
                            className='hover-scroll-overlay-y my-5'
                        >
                            <div
                                className='menu menu-column menu-rounded menu-sub-indention px-3'
                            >
                                {isKlant &&
                                    <>
                                        <Link
                                            to={`${ImportRoutePath.importProfileMapping}`}
                                            className={'dropdown-item p-3'}>Vakprofielen</Link>
                                        <Link
                                            to={`${ImportRoutePath.importSluitpotMapping}`}
                                            className={'dropdown-item p-3'}>Sluitpotten</Link>
                                    </>
                                }

                                <Link
                                    to={`${ImportRoutePath.importSillTypeMapping}`}
                                    className={'dropdown-item p-3'}>Dorpeltypes</Link>
                                <Link
                                    to={`${ImportRoutePath.importCompartmentLayoutMapping}`}
                                    className={'dropdown-item p-3'}>Vakindelingen</Link>
                                {/*<Link*/}
                                {/*    to={`${ImportRoutePath.importArticleMapping}`}*/}
                                {/*    className={'dropdown-item p-3'}>Artikelen</Link>*/}
                                <Link
                                    to={`${ImportRoutePath.importStopProfileMapping}`}
                                    className={'dropdown-item p-3'}>Aanslagprofielen</Link>
                                <Link
                                    to={`${ImportRoutePath.importGlazingprofileMapping}`}
                                    className={'dropdown-item p-3'}>Beglazingsprofielen</Link>
                                <Link
                                    to={`${ImportRoutePath.importGlazingbarMapping}`}
                                    className={'dropdown-item p-3'}>Glaslatten</Link>
                                <Link
                                    to={`${ImportRoutePath.importCornerpiecesMapping}`}
                                    className={'dropdown-item p-3'}>Hoekstukken</Link>
                            </div>
                        </div>
                    </div>


                </div>
                <div className={'col'}>
                    <Outlet/>
                </div>
            </div>
        </>
    );
};

const menuItem = (to: string, title: string) => {

}