/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link } from "react-router-dom";
import { EkoTable } from "../../../../../shared/components/table/EkoTable";
import { TableHeader } from "../../../../../shared/components/table/TableHeader";
import { formatDate } from '../../../../../shared/components/date';
import { useListExpeditionsByWeekOdata } from '../hooks/use-list-expeditions-by-week-odata';
import { EmRoutePath } from '../../EmRoutes';
import {
    ExpeditionPlanningByWeekView
} from '../../../../../modules/api-client/generated';
import Loading from "../../../../../shared/components/Loading";

const ExpeditionPlanningList: React.FC = () => {
    
    const {
        isInitialLoading,
        data: expeditionWeekList,
        isError
    } = useListExpeditionsByWeekOdata(undefined);

    if (isInitialLoading) {
        return <Loading />;
    }

    if (isError) {
        return <>Fout bij het ophalen van de gegevens!</>;
    }

    if (!expeditionWeekList || !expeditionWeekList.value) return <></>;

    return (
        <EkoTable className={'card-xxl-stretch mb-5 mb-xxl-8'}>
            <TableHeader>
                <th className='min-w-40px'>Week</th>                
                <th className='min-w-50px text-center'>Orders</th>
                <th className='min-w-50px text-center'>Dorpels</th>
                <th className='min-w-50px text-center'>Meters</th>
                <th className='min-w-50px text-center'>Neuten</th>
                <th className='min-w-50px text-center'>Latten</th>
            </TableHeader>
            <tbody>
                {expeditionWeekList.value && expeditionWeekList.value.map((expeditionWeek: ExpeditionPlanningByWeekView) =>
                    <tr key={expeditionWeek.plannedExpeditionYear + '' + expeditionWeek.plannedExpeditionWeek}>
                        <td>
                                                        
                            <Link
                            to={EmRoutePath.link(EmRoutePath.planningWeekLink(expeditionWeek.plannedExpeditionYear, expeditionWeek.plannedExpeditionWeek))}
                            className='btn btn-primary'>
                                <span className="d-flex flex-column align-items-center ms-2">
                                    <span className="fs-4 fw-bold">
                                        {expeditionWeek.plannedExpeditionYear === 0 ? 'Ongepland' : expeditionWeek.plannedExpeditionYear + '-' + expeditionWeek.plannedExpeditionWeek}
                                    </span>
                                    <span className={'fs-7'}> {expeditionWeek.weekStartDate && formatDate(expeditionWeek.weekStartDate,'dd-MM')} - {expeditionWeek.weekEndDate && formatDate(expeditionWeek.weekEndDate, 'dd-MM')}</span>
                                </span>
                            </Link>

                        </td>
                        <td className="text-center">
                            {expeditionWeek.orderCount}
                        </td>
                        <td className="text-center">
                            {expeditionWeek.totalSills}
                        </td>
                        <td className="text-center">            
                            {expeditionWeek.sillLength}
                        </td>
                        <td className="text-center">            
                            {expeditionWeek.neutCount}
                        </td>
                        <td className="text-center">
                            {expeditionWeek.lattenCount}
                        </td>
                    </tr>
                )}
            </tbody>
        </EkoTable>
    )
}

export { ExpeditionPlanningList }