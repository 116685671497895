/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from 'react'
import { Link, useSearchParams } from "react-router-dom";
import { KTSVG } from "../../../../../../_metronic/helpers";
import { EkoTable } from "../../../../../shared/components/table/EkoTable";
import { TableHeader } from "../../../../../shared/components/table/TableHeader";
import { formatDate } from '../../../../../shared/components/date';
import { useEkosietFilter } from '../../../../../shared/context/FilterContext';
import { useListExpeditionsOdata } from '../hooks/use-list-expeditions-odata';
import { SmRoutePath } from '../../../sm/SmRoutes';
import { Pagination } from 'react-bootstrap';
import {ExpeditionStatus} from "../../../../../shared/components/ExpeditionStatus";
import Loading from "../../../../../shared/components/Loading";

const ExpeditionListTable: React.FC = () => {

    const [searchParams, setSearchParams] = useSearchParams()
    const [pageSize, setPageSize] = useState(150);
    const [currentPage, setPage] = useState(1);
    const [orderCount, setOrdersCount] = useState(0);
    const [pageCount, setPageCount] = useState(1);
    const skip = (currentPage - 1) * pageSize;
    const { filterQuery } = useEkosietFilter();

    const {
        isInitialLoading,
        data: expeditionList,
        isError,
    } = useListExpeditionsOdata(pageSize, skip, "CustomerCode desc, OrderCode asc", filterQuery, true);

    useEffect(() => {
        const page = searchParams.get('page');
        const pageParamNumber = parseInt(page || '1', 10); // comes in as a string, convert to int

        if (pageParamNumber > 0 && pageParamNumber <= pageCount) {
            setPage(pageParamNumber)
        }
    }, [pageCount, searchParams]);

    useEffect(() => {
        if (expeditionList && expeditionList['@odata.count']) {
            setOrdersCount(expeditionList['@odata.count']!)
        }
    }, [expeditionList]);

    useEffect(() => {
        if (orderCount > 0) {
            setPageCount(Math.ceil(orderCount / pageSize))
        }
    }, [orderCount, pageSize]);

    const paginationItems = useMemo(() => {
        let items = []
        for (let number = 1; number <= pageCount; number++) {
            items.push(
                <Pagination.Item key={number} active={number === currentPage} onClick={() => { setSearchParams(`?${new URLSearchParams({ page: number.toString() })}`) }} >
                    {number}
                </Pagination.Item>,
            );
        }
        return items
    }, [pageCount, currentPage, setSearchParams]);

    if (isInitialLoading) {
        return <Loading />;
    }

    if (isError) {
        return <>Fout bij het ophalen van de expedities!</>;
    }

    if (!expeditionList || !expeditionList.value) return <></>;

    return (
        <>
            <EkoTable className={'card-xxl-stretch mb-5 mb-xxl-8'}>
                <TableHeader>
                    <th className='min-w-40px'>Order Code</th>
                    <th className='min-w-140px'>Klant</th>
                    <th className='min-w-50px text-center'>Gewenst <i className="fas fa-calendar-alt"></i></th>
                    <th className='min-w-50px text-center'>Gepland <i className="fas fa-calendar-alt"></i></th>
                    <th className='min-w-50px text-center'>Aflever <i className="fas fa-calendar-alt"></i></th>
                    <th className='min-w-50px text-end'>Totaal</th>
                    <th className='min-w-50px text-center'>Status</th>
                    {/* <th className='min-w-50px text-end'>Acties</th> */}
                </TableHeader>
                <tbody>
                    {expeditionList.value && expeditionList.value.map((expedition, index) =>
                        <tr key={expedition.id}>
                            <td>

                                <Link to={SmRoutePath.link(SmRoutePath.orderEditLink(expedition.orderId!))}
                                    className='text-hover-primary fs-6'>
                                    {expedition.orderCode}
                                </Link>

                            </td>
                            <td>
                                <div
                                    className='text-dark fw-bold d-block mb-1 fs-6'>
                                    {expedition.customerName}
                                </div>
                            </td>
                            <td className="text-center">
                                <div
                                    className='text-dark fw-bold d-block mb-1 fs-6'>
                                    {
                                        expedition.preferredExpeditionDate
                                            ? formatDate(expedition.preferredExpeditionDate)
                                            : 'Zo snel mogelijk'
                                    }
                                </div>
                                {/*<span className='text-muted fw-semibold text-muted d-block fs-7'>Week: 48</span>*/}
                            </td>
                            <td className="text-center">
                                <div
                                    className='text-dark fw-bold d-block mb-1 fs-6'>
                                    {
                                        expedition.plannedExpeditionDate
                                            ? formatDate(expedition.plannedExpeditionDate) : ""

                                    }
                                </div>
                            </td>

                            <td className="text-center">
                                <div
                                    className='text-dark fw-bold d-block mb-1 fs-6'>
                                    {
                                        expedition.deliveredDate
                                            ? formatDate(expedition.deliveredDate) : ""

                                    }
                                </div>
                            </td>

                            <td className='text-dark fw-bold fs-6 text-end'>
                                €{expedition.totalPrice}
                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                    {expedition.totalProducts} item(s)
                                </span>
                            </td>
                            <td className="text-center">
                                <ExpeditionStatus status={expedition.status} />
                            </td>
                            <td className='text-end'>
                                <Link to={SmRoutePath.link(SmRoutePath.orderEditLink(expedition.orderId!))}>
                                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                </Link>
                            </td>
                        </tr>
                    )}
                </tbody>
            </EkoTable>

            <div>
                <span className={`text-muted`}>{expeditionList.value.length}/{expeditionList["@odata.count"]} orders</span>
                <Pagination className='justify-content-start mt-3'>{paginationItems}</Pagination>
            </div>
        </>
    )
}

export { ExpeditionListTable }