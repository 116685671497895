import React, {useLayoutEffect, useState} from 'react';
import {Formik, FormikHelpers, useFormikContext} from 'formik';
import * as Yup from 'yup';
import {usePostImportSluitpotMapping} from '../hooks/use-post-import-sluitpot-mapping';
import {CreateImportSluitpotMappingCommand} from '../../../../../modules/api-client/generated';
import {useGetImportSluitpotMapping} from '../hooks/use-get-import-sluitpot-mapping';
import {SluitpotBeslagPicker} from '../../../pim/sluitpotbeslag/SluitpotBeslagPicker';
import {CustomerPicker} from '../../../crm/components/CustomerPicker';
import Loading from '../../../../../shared/components/Loading';
import FieldErrors from '../../../../../shared/components/FieldErrors';
import TextField from '../../../../../shared/components/TextField';

const schema = {
	customerId: Yup.string().required('Klant is verplicht.'),
	externalCode: Yup.string().max(50, 'Maximaal 50 karakters.').required('Code is verplicht.'),
	toId: Yup.string().required('Sluitpot Beslag is verplicht.'),
};

const formSchema = () =>
	Yup.lazy(() => {
		return Yup.object().shape(schema);
	});

interface ImportSluitpotMappingUpsertProps {
	id: string | undefined;
	customerId: string | undefined;
	handleSucces?: () => void;
}

const ImportSluitpotMappingUpsert: React.FC<ImportSluitpotMappingUpsertProps> = ({id, customerId, handleSucces}) => {
	const mutation = usePostImportSluitpotMapping();
	const {data: importSluitpotMapping, isInitialLoading, isError} = useGetImportSluitpotMapping(id);

	if (isInitialLoading) return <Loading />;
	if (isError) return <>Error...</>;

	const initialValues: CreateImportSluitpotMappingCommand = {
		id: importSluitpotMapping?.id ?? undefined,
		customerId: customerId ?? importSluitpotMapping?.customer?.id ?? '',
		externalCode: importSluitpotMapping?.externalCode ?? '',
		toId: importSluitpotMapping?.sluitpotBeslag?.id ?? '',
	};

	const onSubmit = async (values: CreateImportSluitpotMappingCommand, {setSubmitting, resetForm, setFieldValue}: FormikHelpers<CreateImportSluitpotMappingCommand>) => {
		await mutation.mutateAsync(values, {
			onSuccess: (data, variables) => {
				resetForm({
					values: {
						id: undefined,
						customerId: customerId ?? '',
						externalCode: '',
						toId: '',
					},
				});			
				if (handleSucces) {
					handleSucces();
				}
			},
			onSettled: () => {
				setSubmitting(false);
			},
		});
	};

	return (
		<Formik initialValues={initialValues} enableReinitialize={true} onSubmit={onSubmit} validationSchema={formSchema}>
			<ImportSluitpotMappingUpsertForm id={id} customerId={customerId} mutation={mutation} />
		</Formik>
	);
};

interface ImportSluitpotMappingUpsertFormProps {
	id: string | undefined;
	customerId: string | undefined;
	mutation: ReturnType<typeof usePostImportSluitpotMapping>;
}

export const ImportSluitpotMappingUpsertForm: React.FC<ImportSluitpotMappingUpsertFormProps> = (props) => {
	const isAdd = !props.id;

	const {values, handleSubmit, handleChange, isSubmitting, errors, setErrors, handleBlur, setFieldValue} = useFormikContext<CreateImportSluitpotMappingCommand>();

	const mergeServerErrors = () => {
		if (props.mutation.serverValidationErrors) {
			setErrors({...errors, ...props.mutation.serverValidationErrors});
		}
	};
	useLayoutEffect(mergeServerErrors, [props.mutation.serverValidationErrors, errors, setErrors]);

	return (
		<form onSubmit={handleSubmit}>
			<div className="row mt-5">
				<div className={'col d-flex flex-column w-100'}>
					<div>
						{!props.customerId && (
							<div className={'my-4'}>
								<label className="form-label" htmlFor={'customerId'}>
									Klant
								</label>
								<CustomerPicker fieldName="customerId" value={values.customerId} className="w-100" onChange={(customerId) => setFieldValue('customerId', customerId)} />

								<FieldErrors field="customerId" />
							</div>
						)}
						<div className={'my-4'}>
							<label className="form-label" htmlFor={'externalCode'}>
								Code
							</label>
							<TextField row={false} name={'externalCode'} />
						</div>
						<div className={'my-4'}>
							<label className="form-label" htmlFor={'toId'}>
								Sluitpot Beslag
							</label>
							<SluitpotBeslagPicker name={'toId'} onlyActive={true}/>
							<FieldErrors field="toId" />
						</div>
					</div>
					<div className=" align-self-end mt-auto">
						<button type="submit" className="btn btn-primary" data-kt-customer-modal-action="submit" disabled={isSubmitting}>
							{!isSubmitting && <span className="indicator-label">{isAdd ? 'Toevoegen' : 'Wijzigen'}</span>}
							{isSubmitting && (
								<span className="indicator-label">
									Even wachten a.u.b
									<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
								</span>
							)}
						</button>
					</div>
				</div>
			</div>
		</form>
	);
};

export default ImportSluitpotMappingUpsert;
